import {
  Prompt,
  PromptCallRequest,
  PromptCallResponse,
  PromptRequest,
  PromptResponse,
  UpdatePromptRequest,
  parsePromptResponse,
} from "@/types/app/prompt";
import { AxiosPromise, AxiosResponse } from "axios";
import { ApiService } from "./api.service";
import { PromptKernelRequest } from "humanloop/api";

export const upsertPrompt = async (request: PromptRequest): AxiosPromise<Prompt> => {
  const response: AxiosResponse<PromptResponse> = await ApiService.post(`/v5/prompts`, request);
  return { ...response, data: parsePromptResponse(response.data) };
};

export const movePrompt = async (promptId: string, update: UpdatePromptRequest): AxiosPromise<Prompt> => {
  const response: AxiosResponse<PromptResponse> = await ApiService.patch(`/v5/prompts/${promptId}`, update);
  return { ...response, data: parsePromptResponse(response.data) };
};

export const callPrompt = async (request: PromptCallRequest): AxiosPromise<PromptCallResponse> => {
  const response: AxiosResponse<PromptCallResponse> = await ApiService.post(`/v5/prompts/call`, request);
  return response;
};
