import {
  Agent,
  AgentCallRequest,
  AgentCallResponse,
  AgentRequest,
  AgentResponse,
  parseAgentResponse,
} from "@/types/app/agent";
import { AxiosPromise, AxiosResponse } from "axios";
import { ApiService } from "./api.service";

export const upsertAgent = async (request: AgentRequest): AxiosPromise<Agent> => {
  const response: AxiosResponse<AgentResponse> = await ApiService.post(`/v5/agents`, request);
  return { ...response, data: parseAgentResponse(response.data) };
};

export const callAgent = async (request: AgentCallRequest): AxiosPromise<AgentCallResponse> => {
  const response: AxiosResponse<AgentCallResponse> = await ApiService.post(`/v5/agents/call`, request);
  return response;
};
