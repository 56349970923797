import { ToolKernelRequest } from "@/types/app/tool";

export const DEFAULT_JSON_SCHEMA_TOOL: ToolKernelRequest = {
  function: {
    name: "json_schema",
    description: "Description of the function documented by the schema",
    parameters: {
      type: "object",
      properties: {},
      required: [],
    },
  },
};

export const DEFAULT_SNIPPET_TOOL: ToolKernelRequest = {
  // function: {
  //   name: "snippet",
  //   description: "Key-value pairs of text content",
  //   parameters: {
  //     type: "object",
  //     required: ["key"],
  //     properties: {
  //       key: {
  //         type: "string",
  //         description: "Key to map to the value in the snippet tool",
  //       },
  //     },
  //   },
  // },
  setup_values: { entries: [{ key: "example-key", value: "value" }] },
};

export const DEFAULT_GET_API_CALL_TOOL: ToolKernelRequest = {
  function: {
    name: "get_api_call",
    description: "Send a GET request to an API",
    parameters: {
      type: "object",
      required: ["query"],
      properties: {
        query: {
          type: "string",
          description: "Query param for query",
        },
      },
    },
  },
  setup_values: {
    api_key: "sk-example-api-key",
    api_key_header: "API-KEY",
    query_params: "query",
    url: "https://example.com",
  },
};

export const DEFAULT_PINECONE_TOOL: ToolKernelRequest = {
  function: null,
  // function: {
  //   name: "pinecone_search",
  //   description: "Return results from a database",
  //   parameters: {
  //     type: "object",
  //     required: ["query"],
  //     properties: {
  //       query: { type: "string", description: "Query to send to database" },
  //       top_k: { type: "integer", description: "Top 5 most similar records to return from database" },
  //     },
  //   },
  // },
  setup_values: {
    pinecone_key: "your-pinecone-api-key",
    pinecone_environment: "us-east1-gcp",
    pinecone_index: "your-pinecone-index",
    // TODO(v5): Figure out why null values result in the backend throwing an error, and if sending empty strings breaks anything.
    // pinecone_fields: null,
    // pinecone_namespace: null,
    // pinecone_fields: "",
    // pinecone_namespace: "",
    openai_key: "your-openai-key",
    openai_model: "text-embedding-3-small",
  },
  // Note that this reflects a deprecated Pinecone API:
  // https://docs.pinecone.io/guides/operations/migrate-to-the-new-api#python
  // https://canyon-quilt-082.notion.site/Pinecone-Python-SDK-v3-0-0-Migration-Guide-056d3897d7634bf7be399676a4757c7b
};

export const DEFAULT_GOOGLE_TOOL: ToolKernelRequest = {
  function: null,
  setup_values: {
    api_key: "your-serpapi-key",
  },
};

export const DEFAULT_PYTHON_TOOL: ToolKernelRequest = {
  function: {
    name: "calculate",
    description: "Performs basic calculations",
    parameters: {
      type: "object",
      properties: {
        operation: {
          type: "string",
          enum: ["add", "subtract", "multiply", "divide"],
          description: "The operation to perform",
        },
        a: { type: "number", description: "First number" },
        b: { type: "number", description: "Second number" },
      },
      required: ["operation", "a", "b"],
    },
  },
  source_code:
    "def calculate(operation: str, a: float, b: float) -> float:\n    if operation == 'add': return a + b\n    elif operation == 'subtract': return a - b\n    elif operation == 'multiply': return a * b\n    elif operation == 'divide': return a / b\n    else: raise ValueError(f'Unknown operation: {operation}')",
};
