import { RadixAppDialog } from "@components/library/AppDialog";
import { Directory, DirectoryFile, directoryFileFromFile, useDirectory } from "@/services/directories.service";
import { getOrganizationActiveProduct, getFeatureFlag } from "@/lib/organization";
import { useRequireAuth } from "@/lib/use-auth";
import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { OptionCard } from "./OptionCard";
import { upsertTool, useToolTemplates } from "@/services/tools.service";
import { useState } from "react";
import _ from "lodash";
import { ToolImage } from "../Tools/ToolImage";
import { upsertPrompt } from "@/services/prompts.service";
import { ToastVariant, hlToast } from "@components/library/Toast";
import { Book, CodeSimple, FolderSimple, User } from "@phosphor-icons/react";
import { FileType } from "@/types/app/file";
import Button from "@components/library/Button";
import { ArrowUpRightIcon } from "@components/library/Icons";
import { DOCS_EVALUATIONS, DOCS_FILES, DOCS_TOOLS, LIBRARY_URL } from "@/lib/constants";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import { upsertDataset } from "@/services/datasets.service";
import { Lissajous2 } from "@components/library/Lissajous";
import { ToolKernelRequest, ToolTemplateResponse, ToolType } from "@/types/app/tool";
import { ArrowLeft } from "@phosphor-icons/react/dist/ssr";
import IconButton from "@components/library/IconButton";
import { Tag } from "@components/library/v2/Tag";
import { DEFAULT_PROMPT } from "@/lib/defaults/prompts";
import {
  DEFAULT_GET_API_CALL_TOOL,
  DEFAULT_GOOGLE_TOOL,
  DEFAULT_JSON_SCHEMA_TOOL,
  DEFAULT_PINECONE_TOOL,
  DEFAULT_PYTHON_TOOL,
  DEFAULT_SNIPPET_TOOL,
} from "@/lib/defaults/tools";
import { DEFAULT_DATASET } from "@/lib/defaults/datasets";
import { EvaluatorSpec, EvaluatorType } from "@/types/app/evaluator";
import { upsertEvaluator } from "@/services/evaluators.service";
import { DEFAULT_HUMAN_EVALUATOR, DEFAULT_LLM_EVALUATOR, DEFAULT_PYTHON_EVALUATOR } from "@/lib/defaults/evaluators";
import { upsertFlow } from "@/services/flow.service";
import { DEFAULT_FLOW } from "@/lib/defaults/flows";
import Tooltip from "@components/library/Tooltip";
import { BookOpenIcon } from "@heroicons/react/outline";
import { upsertAgent } from "@/services/agent.service";
import { DEFAULT_AGENT } from "@/lib/defaults/agents";

interface Props {
  open: boolean;
  onClose: () => void;
  onCreate: (file: DirectoryFile) => void;
  directory?: Directory;
  onCreateDirectory?: () => void;
}

export const FileCreateModal = ({ open, onClose, onCreate, directory, onCreateDirectory }: Props) => {
  const { hasAccess } = useRequireAuth();
  const { organization, slug } = useActiveOrganization();
  const [step, setStep] = useState<"file-type" | "tool" | "integrated-tool" | "evaluator" | "flow">("file-type");
  const [creating, setCreating] = useState<string | null>(null);
  const { toolTemplates } = useToolTemplates();

  const { mutate: refreshDirectory } = useDirectory(directory?.id || null);

  if (!organization) return null;

  const codeEvaluatorsEnabled = hasAccess("evaluations") || false;

  const handleClose = () => {
    setStep("file-type");
    onClose();
  };

  const handleCreate = async (
    createType: FileType | "directory",
    subtype?: { toolType?: ToolType; evaluatorType?: EvaluatorType },
  ) => {
    if (createType === "directory") {
      onClose();
      onCreateDirectory?.();
      return;
    }

    try {
      switch (createType) {
        case "prompt": {
          const file = (
            await upsertPrompt({
              directory_id: directory?.id,
              commit_message: "Initial commit",
              ...DEFAULT_PROMPT,
            })
          ).data;
          onCreate(directoryFileFromFile(file));
          break;
        }

        case "tool": {
          if (subtype?.toolType) {
            setCreating(subtype.toolType);
            let toolKernelRequest: ToolKernelRequest;
            switch (subtype.toolType) {
              case "json_schema":
                toolKernelRequest = DEFAULT_JSON_SCHEMA_TOOL;
                break;
              case "snippet":
                toolKernelRequest = DEFAULT_SNIPPET_TOOL;
                break;
              case "get_api_call":
                toolKernelRequest = DEFAULT_GET_API_CALL_TOOL;
                break;
              case "pinecone_search":
                toolKernelRequest = DEFAULT_PINECONE_TOOL;
                break;
              case "google":
                toolKernelRequest = DEFAULT_GOOGLE_TOOL;
                break;
              case "python":
                toolKernelRequest = DEFAULT_PYTHON_TOOL;
                break;
              default:
                const _exhaustiveCheck: never = subtype.toolType;
                throw new Error(`Cannot create Tool of type: ${subtype.toolType}`);
            }

            const file = (
              await upsertTool({
                directory_id: directory?.id,
                commit_message: "Initial commit",
                tool_type: subtype.toolType,
                ...toolKernelRequest,
              })
            ).data;
            onCreate(directoryFileFromFile(file));
          }
          break;
        }

        case "dataset": {
          const file = (
            await upsertDataset({
              directory_id: directory?.id,
              commit_message: "Initial commit",
              ...DEFAULT_DATASET,
            })
          ).data;
          onCreate(directoryFileFromFile(file));
          break;
        }

        case "evaluator": {
          if (subtype?.evaluatorType) {
            let spec: EvaluatorSpec;
            switch (subtype.evaluatorType) {
              case "llm":
                spec = DEFAULT_LLM_EVALUATOR;
                break;
              case "python":
                spec = DEFAULT_PYTHON_EVALUATOR;
                break;
              case "human":
                spec = DEFAULT_HUMAN_EVALUATOR;
                break;
              case "external":
                throw new Error("Cannot create external evaluator from the UI");
              default:
                const _exhaustiveCheck: never = subtype.evaluatorType;
                throw new Error(`Unknown evaluator type: ${subtype.evaluatorType}`);
            }

            const file = (
              await upsertEvaluator({
                directory_id: directory?.id,
                commit_message: "Initial commit",
                spec,
              })
            ).data;
            onCreate(directoryFileFromFile(file));
          }
          break;
        }

        case "flow": {
          const file = (
            await upsertFlow({
              directory_id: directory?.id,
              commit_message: "Initial commit",
              ...DEFAULT_FLOW,
            })
          ).data;
          onCreate(directoryFileFromFile(file));
          break;
        }

        case "agent": {
          const file = (
            await upsertAgent({
              directory_id: directory?.id,
              commit_message: "Initial commit",
              ...DEFAULT_AGENT,
            })
          ).data;
          onCreate(directoryFileFromFile(file));
          break;
        }

        default: {
          const _exhaustiveCheck: never = createType;
          break;
        }
      }

      onClose();
    } catch (e: any) {
      hlToast({
        title: `Failed to create ${createType}`,
        description: e.response?.data?.detail?.msg || e.response?.data?.detail || `Contact us for more details.`,
        variant: ToastVariant.Error,
      });
    } finally {
      directory && refreshDirectory();
      setTimeout(() => {
        setCreating(null);
        setStep("file-type"); // Reset the step to "file-type"
      }, 1000);
    }
  };

  const getDescription = () => {
    let descriptionCopy = "Create a new file or directory";
    let descriptionLink = DOCS_FILES;

    if (step == "tool" || step == "integrated-tool") {
      descriptionCopy = "Tools enable Prompts to take action";
      descriptionLink = DOCS_TOOLS;
    }
    if (step == "evaluator") {
      descriptionCopy = "Measure LLM generations";
      descriptionLink = DOCS_EVALUATIONS;
    }
    return (
      <div className="flex items-center gap-8">
        {descriptionCopy}
        <Button
          styling="ghost"
          shade={"blue"}
          size={24}
          IconRight={ArrowUpRightIcon}
          target="_blank"
          href={descriptionLink}
        >
          Docs
        </Button>
      </div>
    );
  };

  const previousStep = getPreviousStep(step);

  return (
    <RadixAppDialog
      open={open}
      onClose={handleClose}
      width={step == "file-type" ? "wide" : "normal"}
      title={
        <div className="flex items-center justify-center gap-6">
          {previousStep && (
            <IconButton Icon={ArrowLeft} size={32} onClick={() => previousStep && setStep(previousStep)} />
          )}
          {getTitleForStep(step)}
        </div>
      }
      description={getDescription()}
    >
      <div className="flex flex-col gap-40">
        <div className="flex flex-col gap-12">
          {step == "file-type" && <FileTypeSelectStep setStep={setStep} onCreate={handleCreate} creating={creating} />}

          {step == "tool" && toolTemplates && (
            <NewToolModalStep
              toolTemplates={toolTemplates}
              creating={creating}
              onCreate={handleCreate}
              setStep={setStep}
            />
          )}
          {step == "integrated-tool" && toolTemplates && (
            <NewIntegratedToolStep toolTemplates={toolTemplates} creating={creating} onCreate={handleCreate} />
          )}
          {step == "evaluator" && (
            <NewEvaluatorModalStep
              creating={creating}
              onCreate={handleCreate}
              codeEvaluatorsEnabled={codeEvaluatorsEnabled}
            />
          )}
        </div>
        <Button
          styling="outline"
          onClick={handleClose}
          IconLeft={BookOpenIcon}
          size={40}
          href={`${slug}${LIBRARY_URL}`}
        >
          Browse Library
        </Button>
      </div>
    </RadixAppDialog>
  );
};

const getPreviousStep = (step: "file-type" | "tool" | "integrated-tool" | "evaluator" | "flow") => {
  switch (step) {
    case "file-type":
      return null;
    case "tool":
      return "file-type";
    case "integrated-tool":
      return "tool";
    case "evaluator":
      return "file-type";
    case "flow":
      return "file-type";
  }
};

const getTitleForStep = (step: "file-type" | "tool" | "integrated-tool" | "evaluator" | "flow") => {
  switch (step) {
    case "file-type":
      return "New...";
    case "tool":
      return "New Tool";
    case "integrated-tool":
      return "New Integration Tool";
    case "evaluator":
      return "New Evaluator";
    case "flow":
      return "New Flow";
  }
};

const FileTypeSelectStep = ({
  creating,
  setStep,
  onCreate,
}: {
  creating: string | null;
  setStep: (step: "file-type" | "tool" | "evaluator") => void;
  onCreate: (
    createType: FileType | "directory",
    subtype?: { toolType?: ToolType; evaluatorType?: EvaluatorType },
  ) => void;
}) => {
  return (
    <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
      <OptionCard
        title="Agent"
        badge={<EntityIcon type="agent" size={20} />}
        description="Autonomous reasoning and action taking"
        onClick={() => onCreate("agent")}
      />
      <OptionCard
        badge={<EntityIcon type="prompt" size={20} />}
        title="Prompt"
        description="Generate text using an LLM"
        onClick={() => onCreate("prompt")}
        creating={creating === "prompt"}
      />
      {
        <OptionCard
          badge={<EntityIcon type="tool" size={20} />}
          title="Tool"
          description="Enable Prompts to do more"
          onClick={() => setStep("tool")}
          rightIcon
        />
      }
      <OptionCard
        badge={<EntityIcon type="evaluator" size={20} />}
        title="Evaluator"
        description="Monitor and Evaluate LLM generations"
        onClick={() => setStep("evaluator")}
        rightIcon
      />
      <OptionCard
        badge={<EntityIcon type="dataset" size={20} />}
        title="Dataset"
        description="Collection of Datapoints for Evaluations"
        onClick={() => onCreate("dataset")}
      />
      <OptionCard
        title="Flow"
        badge={<EntityIcon type="flow" size={20} />}
        description="Evaluate multi-step apps"
        onClick={() => onCreate("flow")}
      />
      <OptionCard
        badge={<FolderSimple size={20} />}
        title="Directory"
        description="Organize Projects and related Files"
        onClick={() => onCreate("directory")}
      />
    </div>
  );
};

const NewToolModalStep = ({
  creating,
  toolTemplates,
  onCreate,
  setStep,
}: {
  creating: string | null;
  toolTemplates: ToolTemplateResponse[];
  onCreate: (fileType: "tool", subtype?: { toolType?: ToolType; evaluatorType?: EvaluatorType }) => void;
  setStep: (step: "integrated-tool") => void;
}) => {
  const jsonSchemaTemplate = toolTemplates?.find((toolTemplate) => toolTemplate.name === "json_schema");
  const snippetTemplate = toolTemplates?.find((toolTemplate) => toolTemplate.name === "snippet");

  return (
    <>
      <OptionCard
        badge={<EntityIcon type="tool" size={20} />}
        title={_.startCase("Python")}
        description={"Write custom Python code"}
        onClick={() => onCreate("tool", { toolType: "python" })}
        creating={creating === "python"}
      />
      {jsonSchemaTemplate && (
        <OptionCard
          badge={<EntityIcon type="tool" size={20} />}
          title={_.startCase(jsonSchemaTemplate?.name)}
          description={jsonSchemaTemplate?.description}
          onClick={() => onCreate("tool", { toolType: "json_schema" })}
          creating={creating === "json_schema"}
        />
      )}
      {snippetTemplate && (
        <OptionCard
          badge={<EntityIcon type="tool" size={20} />}
          title={_.startCase(snippetTemplate?.name)}
          description={snippetTemplate?.description}
          onClick={() => onCreate("tool", { toolType: "snippet" })}
          creating={creating === "snippet"}
        />
      )}
      <OptionCard
        badge={<EntityIcon type="tool" size={20} />}
        title="Connect an Integration"
        description="Setup external apps like Pinecone, Google, and more"
        onClick={() => setStep("integrated-tool")}
        rightIcon
      />
    </>
  );
};

const NewIntegratedToolStep = ({
  creating,
  onCreate,
  toolTemplates,
}: {
  creating: string | null;
  onCreate: (fileType: "tool", subtype?: { toolType?: ToolType; evaluatorType?: EvaluatorType }) => void;
  toolTemplates: ToolTemplateResponse[];
}) => {
  // We display the following tools in the initial tool selection screen
  const toolsToFilter = ["json_schema", "snippet", "mock"];

  return (
    <>
      {toolTemplates
        ?.filter((toolTemplate) => !toolsToFilter.includes(toolTemplate.name))
        .map((toolTemplate, i) => (
          <OptionCard
            key={i}
            badge={<ToolImage toolType={toolTemplate.name} />}
            title={_.startCase(toolTemplate.name)}
            description={toolTemplate.description}
            onClick={() => onCreate("tool", { toolType: toolTemplate.name as ToolType })}
            creating={creating === toolTemplate.name}
          />
        ))}
    </>
  );
};

const NewEvaluatorModalStep = ({
  creating,
  onCreate,
  codeEvaluatorsEnabled,
}: {
  creating: string | null;
  onCreate: (fileType: "evaluator", subtype?: { toolType?: ToolType; evaluatorType?: EvaluatorType }) => void;
  codeEvaluatorsEnabled: boolean;
}) => {
  return (
    <>
      <OptionCard
        key={"llm"}
        badge={<Lissajous2 />}
        title={"AI"}
        description={"Use an LLM to evaluate"}
        onClick={() => onCreate("evaluator", { evaluatorType: "llm" })}
        creating={creating === "llm"}
      />
      <OptionCard
        key={"python"}
        badge={<CodeSimple size={20} />}
        title={"Code"}
        description={"Write custom Python evaluation code"}
        onClick={() => onCreate("evaluator", { evaluatorType: "python" })}
        creating={creating === "python"}
        disabled={!codeEvaluatorsEnabled}
        tag={
          codeEvaluatorsEnabled ? null : (
            <Tooltip content="Contact us to upgrade your plan">
              <Tag size={20} shade="violet">
                Upgrade
              </Tag>
            </Tooltip>
          )
        }
      />
      <OptionCard
        key={"human"}
        badge={<User size={20} />}
        title={"Human"}
        description={"Manual evaluations by humans"}
        onClick={() => onCreate("evaluator", { evaluatorType: "human" })}
        creating={creating === "human"}
      />
    </>
  );
};
