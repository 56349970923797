import { AxiosPromise, AxiosResponse } from "axios";
import { ApiService } from "./api.service";
import {
  Tool,
  ToolCall,
  ToolCallRequest,
  ToolCallResponse,
  ToolRequest,
  ToolResponse,
  ToolTemplateResponse,
  UpdateToolRequest,
  parseToolCallResponse,
  parseToolResponse,
} from "@/types/app/tool";
import useSWR, { SWRConfiguration } from "swr";
import { getAuthToken } from "@/lib/use-auth";

export const upsertTool = async (request: ToolRequest): AxiosPromise<Tool> => {
  const response: AxiosResponse<ToolResponse> = await ApiService.post(`/v5/tools`, request);
  return { ...response, data: parseToolResponse(response.data) };
};

export const moveTool = async (toolId: string, update: UpdateToolRequest): AxiosPromise<Tool> => {
  const response: AxiosResponse<ToolResponse> = await ApiService.patch(`/v5/tools/${toolId}`, update);
  return { ...response, data: parseToolResponse(response.data) };
};

export const useToolTemplates = (swrOptions: SWRConfiguration<ToolTemplateResponse[]> = {}) => {
  const { data, error, mutate } = useSWR<ToolTemplateResponse[]>([`/v5/tools/templates`, getAuthToken()], swrOptions);
  return { toolTemplates: data, error: error, loading: !data && !error, mutate };
};

export const callTool = async (request: ToolCallRequest): AxiosPromise<ToolCall> => {
  const response: AxiosResponse<ToolCallResponse> = await ApiService.post(`/v5/tools/call`, request);
  return { ...response, data: parseToolCallResponse(response.data) };
};
