import { ToolType } from "@/types/app/tool";
import { CodeIcon, GlobeAltIcon } from "@heroicons/react/outline";

export const ToolImage = ({ toolType }: { toolType: ToolType | string }): JSX.Element => {
  switch (toolType) {
    case "google":
      return <img src="/assets/companies/google-mark.svg" className="h-40 w-40" alt={""} />;
    case "get_api_call":
      return <GlobeAltIcon className="h-24 w-24" />;
    case "pinecone_search":
    case "Pinecone search":
      return <img src="/assets/companies/pinecone-mark.svg" className="h-24 w-24" alt={""} />;
    case "snippet":
      return <CodeIcon className="h-24 w-24" />;
    case "json_schema":
      return <CodeIcon className="h-24 w-24" />;
    default:
      return <CodeIcon className="h-24 w-24" />;
  }
};
