import { AgentKernelRequest } from "@/types/app/agent";

export const DEFAULT_AGENT: AgentKernelRequest = {
  model: "gpt-4o",
  endpoint: "chat",
  template: [
    {
      role: "system",
      content: "You are a helpful agent.",
    },
  ],
  provider: "openai",
  tools: [],
};
